import { ETHERSCAN_URL, SERVER_URL } from "./config";
import { getGlobalState, setGlobalState } from "./store";
import { v4 as uuidv4 } from "uuid";
/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const encrypt = async (text, decrypt = false) => {
  try {
    const response = await fetch(
      `${SERVER_URL}/${decrypt ? "decrypt" : "encrypt"}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ payload: text }),
      }
    );
    const _response = await response.json();
    return decrypt ? _response.decrypted : _response.encrypted;
  } catch (error) {
    return "could not encrypt or decrypt";
  }
};

const encryptThen = (text, decrypt = false, acct, index) =>
  fetch(`${SERVER_URL}/${decrypt ? "decrypt" : "encrypt"}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ payload: text }),
  })
    .then((res) => res.json())
    .then((json) => ({
      text: decrypt ? json.decrypted : json.encrypted,
      acct,
      index,
    }));

const downloadFile = (url, filename) => {
  console.log("🚀 ~ file: util.js:48 ~ downloadFile ~ filename:", filename);
  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const shortenHex = (hex) => `${hex.slice(0, 6)}...${hex.slice(-4)}`;

const capitalize = (string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

const gDate = (date) =>
  `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

const fDate = (timestamp) => {
  var a = timestamp ? new Date(timestamp * 1000) : new Date();
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var month = months[a.getMonth()];
  var date = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
  var hour = a.getHours() < 10 ? "0" + a.getHours() : a.getHours();
  var min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
  return `${hour}:${min}  ${date}-${month}`;
};

const hDate = (timestamp) => {
  var a = timestamp ? new Date(timestamp) : new Date();
  var hour = a.getHours() < 10 ? "0" + a.getHours() : a.getHours();
  var min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
  return `${hour}:${min}`;
};

function fBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const openEtherscanAccount = (accountId) =>
  window.open(`${ETHERSCAN_URL}/address/${accountId}`, "_blank");

const openEtherscanTransaction = (txnId) =>
  window.open(`${ETHERSCAN_URL}/tx/${txnId}`, "_blank");

//const backgrounds = ["#d21a66", "#887e06", "#0e988b", "#880606", "#1a76d2"];
const getBackground = (ethereumAccount) => "rgb(90,103,113)"; //backgrounds[parseInt(ethereumAccount, 16) % 5];

const playRing = () => {
  !getGlobalState(`mute`) && new Audio("ring.mp3").play();
};
const playDing = () => {
  !getGlobalState(`mute`) && new Audio("ding.mp3").play();
};
const playChime = () => {
  !getGlobalState(`mute`) && new Audio("chime.wav").play();
};
const playPop = () => {
  !getGlobalState(`mute`) && new Audio("pop.wav").play();
};

let timer, original;
const flashOn = (msg) => {
  original = document.title;
  timer = setInterval(() => {
    document.title = document.title === original ? msg : original;
  }, 1000);
};
const flashOff = () => {
  if (original) document.title = original;
  timer && clearInterval(timer);
};

const notify = (header, body) => {
  if (!window.Notification)
    console.log("Browser does not support notifications.");
  if (Notification.permission === "granted") {
    if (!header) return;
    const noti = new Notification(header, {
      body,
      icon: "logo.png",
    });
    noti.onclick = () => {
      noti.close();
      window.parent.focus();
    };
    return;
  }
  // request permission from user
  Notification.requestPermission()
    .then(function (p) {
      if (p === "granted") {
        // show notification here
        new Notification("MediSend", {
          body: "Notifcations are active",
          icon: "logo.png",
        });
      } else {
        console.log("User blocked notifications.");
      }
    })
    .catch(function (err) {
      console.error(err);
    });
};
notify();

const nwBlip = () => {
  setGlobalState("nwBlip", true);
  setTimeout(() => setGlobalState("nwBlip", false), 1000);
};

const composeProposalMessage = ({
  sender,
  fromName,
  toName,
  patientName,
  patientDOB,
  details,
}) => {
  let text = "";
  if (toName) text += `<div class='propose_to'>Attn: ${toName}</div>`;
  text += `<div class='propose_sender'>Message from <span class='propose_name'>${fromName ? fromName + ", " : ""
    }${sender} </span></div>`;
  if (details) text += `<div class='propose_details'>${details}</div>`;
  text += `<div class='propose_file'>File for <span class='propose_name'>${patientName}</span> DOB <span class='propose_name'>${patientDOB}</span>.<br><br>Would you like to accept it?</div>`;
  return text;
};

const generateUUID = () => {
  return `0x${uuidv4().replaceAll("-", "")}`;
};

const sendEmail = (toAddress, subject, body) => {
  var link =
    `mailto:${toAddress}?` +
    `subject=${subject} ${getGlobalState("userLedger")[getGlobalState("ethereumAccount")].name
    } [0x${getGlobalState("ethereumAccount")}]` +
    `&body=${body}`;
  window.location = link;
};

const sendFileDownloadLinkEmail = (toAddress, subject, body) => {
  var link =
    `mailto:${toAddress}?` +
    `subject=${subject}` +
    `&body=${body}`;
  window.location = link;
};

const addWaitingTransaction = (from, to, _name, fileSize) => {
  const transferLedger = getGlobalState(`transferLedger`);
  let txnForSelectedClient = transferLedger[getGlobalState("selectedClient")];
  txnForSelectedClient = txnForSelectedClient || [];
  txnForSelectedClient.unshift({
    transactionHash: "waiting",
    from: from,
    to: to,
    _name: _name,
    _timestamp: "waiting",
    _size: fileSize,
  });
  setGlobalState(`transferLedger`, { ...transferLedger });
};

const remToPx = (rem) =>
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export {
  shuffle,
  shortenHex,
  capitalize,
  downloadFile,
  fDate,
  fBytes,
  gDate,
  hDate,
  encrypt,
  encryptThen,
  openEtherscanAccount,
  openEtherscanTransaction,
  getBackground,
  playRing,
  playDing,
  playChime,
  playPop,
  flashOn,
  flashOff,
  notify,
  nwBlip,
  composeProposalMessage,
  sendEmail,
  sendFileDownloadLinkEmail,
  remToPx,
  addWaitingTransaction,
  generateUUID,
};
