import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Icon from "@mdi/react";
import { mdiCallMissed, mdiWifiOff } from "@mdi/js";
import { useGlobalState } from "../../store";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    row: {
      "&:hover": {
        backgroundColor: "rgb(240,240,240) !important",
        cursor: "pointer",
      },
    },
    selected: {
      background: "rgb(240,240,240)",
      color: "white",
    },
    green: {
      display: "inline-block",
      borderRadius: "50%",
      background: "green",
      width: "1rem",
      height: "1rem",
      marginRight: "1rem",
      verticalAlign: "text-bottom",
    },
    grey: {
      display: "inline-block",
      borderRadius: "50%",
      background: "grey",
      width: "1rem",
      height: "1rem",
      marginRight: "1rem",
      verticalAlign: "text-bottom",
    },
    icon: {
      width: "1rem",
      height: "1rem",
      marginRight: "1rem",
      verticalAlign: "text-bottom",
    },
    missedCall: {
      float: "right",
    },
  })
);

export default function ClientList() {
  const classes = useStyles();
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [activeClientList] = useGlobalState("activeClientList");
  const [userLedger] = useGlobalState("userLedger");
  const [selectedClient, setSelectedClient] = useGlobalState("selectedClient");
  const [, setShowUploader] = useGlobalState("showUploader");
  const [searchText] = useGlobalState("searchText");
  const [missedCalls, setMissedCalls] = useGlobalState("missedCalls");

  return (
    <div className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {Object.keys(userLedger)
            .filter(
              (client) =>
                client !== ethereumAccount &&
                userLedger[client].name
                  .toUpperCase()
                  .includes(searchText.toUpperCase())
            )
            .sort((a, b) => {
              const isANonMedisend = userLedger[a].name.toLowerCase() === 'non-medisend';
              const isBNonMedisend = userLedger[b].name.toLowerCase() === 'non-medisend';

              if (isANonMedisend && !isBNonMedisend) return -1;  // A is non-medisend, B is not
              if (!isANonMedisend && isBNonMedisend) return 1;   // B is non-medisend, A is not

              // If both are non-medisend or not, sort by online status (online first)
              const isAOnline = activeClientList.includes(a);
              const isBOnline = activeClientList.includes(b);

              if (isAOnline && !isBOnline) return -1;  // A is online, B is offline
              if (!isAOnline && isBOnline) return 1;   // B is online, A is offline

              // If both are online or offline, sort alphabetically by name
              return userLedger[a].name.localeCompare(userLedger[b].name);
            })

            .map((client) => {
              const { icon, name } = userLedger[client];
              const online = activeClientList.includes(client);
              return (
                <TableRow
                  className={`${classes.row} ${selectedClient === client ? classes.selected : ""
                    }`}
                  key={client}
                  onClick={() => {
                    setShowUploader(false);
                    setShowUploader(false);
                    setSelectedClient(client);
                    setMissedCalls(missedCalls.filter((c) => c !== client));
                  }}
                >
                  <TableCell>
                    {icon ? (
                      <img
                        src={icon}
                        alt="x"
                        className={classes.icon}
                        style={{
                          opacity: online ? 1 : 0.6,
                        }}
                      />
                    ) : (
                      <div className={online ? classes.green : classes.grey} />
                    )}
                    <Typography
                      style={{
                        display: "inline",
                        fontWeight: online ? "bold" : "normal",
                        opacity: 0.8,
                      }}
                    >
                      {name}
                    </Typography>
                    {missedCalls.includes(client) && (
                      <Icon
                        className={classes.missedCall}
                        path={mdiCallMissed}
                        size={0.8}
                        color="red"
                      />
                    )}
                    {!online && (
                      <Icon
                        className={classes.missedCall}
                        path={mdiWifiOff}
                        size={0.8}
                        color="rgba(0,0,0,0.5)"
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
}
