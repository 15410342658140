import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { addUser } from "../../blockchain";
import { useGlobalState } from "../../store";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { SERVER_URL } from "../../config";

const styles = (theme) => ({
  root: {
    padding: "1rem 0 3rem 0",
    margin: "2rem 4rem 0",
    border: "1px solid rgba(0,0,0,0.1)",
    width: "calc(100% - 8rem)",
  },
  button: {
    verticalAlign: "top",
    marginLeft: "0.5rem",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#00000006",
    },
  },
  tableCell: {
    fontWeight: "bold",
    padding: "0.5rem 3rem",
    color: "#333333",
  },
  progress: {
    position: "absolute",
    width: "4rem",
    height: "2.25rem",
    top: "0",
    borderRadius: "4px",
    opacity: "0.4",
  },
});

function AddUser(props) {
  const { classes, plans } = props;
  const [, setNotification] = useGlobalState("notification");
  const [nam, setNam] = useState();
  const [address, setAddress] = useState();
  const [hpio, setHpio] = useState();
  const [url, setUrl] = useState();
  const [icon, setIcon] = useState();
  const [details, setDetails] = useState();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [adding, setAdding] = useState(false);

  // add user with selected plan
  const AdduserPlan = async () => {
    const response = await fetch(`${SERVER_URL}/userplan`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: address,
        planId: selectedPlan.planId,
        planDuration: selectedPlan.timeDuration,
      }),
    });
    const jsonResponse = await response.json();
    // console.log("New user data added", jsonResponse);
    return jsonResponse;
  };
  const _addUser = async () => {
    try {
      setAdding(true);
      await addUser(address, nam, hpio, url, icon, details);
      await AdduserPlan();
      setAdding(false);
      setAddress("");
      setNam("");
      setHpio("");
      setUrl("");
      setIcon("");
      setDetails("");
      setSelectedPlan("");
      setNotification({ type: "success", text: "User added successfully" });
    } catch (error) {
      setAdding(false);
    }
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <TextField
        id="address"
        label={"Ethereum Address"}
        value={address || ""}
        style={{ width: "12rem", marginRight: "3rem" }}
        onChange={({ target }) => setAddress(target.value)}
      />
      <TextField
        id="name"
        label={"Name"}
        value={nam || ""}
        style={{ width: "12rem", marginRight: "3rem" }}
        onChange={({ target }) => setNam(target.value)}
      />
      <TextField
        id="hpio"
        label={"HPIO"}
        value={hpio || ""}
        style={{ width: "12rem", marginRight: "3rem" }}
        onChange={({ target }) => setHpio(target.value)}
      />
      <TextField
        id="url"
        label={"Url"}
        value={url || ""}
        style={{ width: "12rem", marginRight: "3rem" }}
        onChange={({ target }) => setUrl(target.value)}
      />
      <TextField
        id="icon"
        label={"Icon Url"}
        value={icon || ""}
        style={{ width: "12rem", marginRight: "3rem" }}
        onChange={({ target }) => setIcon(target.value)}
      />
      <TextField
        id="details"
        label={"Details"}
        value={details || ""}
        style={{ width: "12rem", marginRight: "3rem" }}
        onChange={({ target }) => setDetails(target.value)}
      />
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="plan-label">Select Plan</InputLabel>
        <Select
          labelId="plan-label"
          id="plan"
          value={selectedPlan || ""}
          onChange={(event) => setSelectedPlan(event.target.value)}
          style={{ width: "12rem", marginRight: "3rem" }}
          renderValue={(p) => p.planName}
        >
          {plans.map((plan, index) => (
            <MenuItem key={index} value={plan}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>{plan.planName}</span>
                <span>Device Limit: {plan.maxDevice}</span>
                <span>Expiry: {plan.timeDuration} Year</span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div
        style={{
          display: "inline-block",
          marginTop: "0.5rem",
          position: "relative",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ verticalAlign: "bottom" }}
          disabled={!address || !nam || !hpio || !selectedPlan}
          onClick={_addUser}
        >
          Add
        </Button>
        {adding && (
          <LinearProgress className={classes.progress} color={"primary"} />
        )}
      </div>
    </Paper>
  );
}

export default withStyles(styles)(AddUser);