import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useGlobalState } from "../../store";
import { removeUser } from "../../blockchain";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SERVER_URL } from "../../config";
import DialogActions from "@material-ui/core/DialogActions";
import moment from 'moment';

const styles = (theme) => ({
  root: {
    margin: "2rem 4rem",
    border: "1px solid rgba(0,0,0,0.1)",
    width: "calc(100% - 8rem)",
  },
  button: {
    verticalAlign: "top",
    marginLeft: "0.5rem",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#00000006",
    },
  },
  tableCell: {
    fontWeight: "bold",
    padding: "0.5rem 3rem",
    color: "#333333",
  },
  link: {
    textDecoration: "underline",
    "&:hover": {
      color: "#cc0000",
      cursor: "pointer",
    },
  },
  dialogHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    cursor: "pointer",
  },
});

function UserTable(props) {
  const { classes, plans } = props;
  const [userLedger] = useGlobalState("userLedger");
  const [, setConfirm] = useGlobalState("confirm");
  const [removing, setRemoving] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedUserPlan, setSelectedUserPlan] = useState(null);
  const [openEditDialog, setEditDialog] = useState(false);

  const _removeUser = (address, name) => {
    setConfirm({
      show: true,
      text: `Do you want to remove ${name} from MediSend?`,
      ok: async () => {
        try {
          setRemoving(address);
          await removeUser(address);
          setRemoving(null);
        } catch (e) {
          setRemoving(null);
        }
      },
    });
  };

  const editPlan = async (address) => {
    let userId = address;
    const response = await fetch(
      `${SERVER_URL}/getUserWisePlanData?userId=${userId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const jsonResponse = await response.json();
    const data = jsonResponse.data;
    console.log("getuserwiseplanData", data);
    setSelectedUserPlan(data);
    setSelectedPlan(data);
    setEditDialog(true);
  };

  const openPlanDialog = async (address) => {
    let userId = address;
    const response = await fetch(
      `${SERVER_URL}/getUserWisePlanData?userId=${userId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const jsonResponse = await response.json();
    const data = jsonResponse.data;
    setSelectedPlan(data);
    setOpenDialog(true);
  };

  const saveUserPlan = async (userId) => {
    console.log("userId in edit plan", userId);
    const response = await fetch(
      `${SERVER_URL}/edituserplan?userId=${userId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          planId: selectedPlan.planId,
          planDuration: selectedPlan.timeDuration,
        }),
      }
    );
    const jsonResponse = await response.json();
    console.log("edit successfully", jsonResponse.data);
    setEditDialog(false);
  };

  const closePlanDialog = () => {
    setSelectedPlan(null);
    setOpenDialog(false);
    setEditDialog(false);
  };

  if (!userLedger) return <div />;
  return (
    <Paper className={classes.root} elevation={0}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            {Object.values(userLedger).map((client, i) => {
              // console.log(userLedger)
              const { address, name, hpio, url, details } = client;
              return (
                <TableRow className={classes.tableRow} key={i}>
                  <TableCell
                    className={`${classes.tableCell} ${classes.link}`}
                    onClick={() =>
                      window.open(
                        `https://ropsten.etherscan.io/address/${address}`,
                        "_blank"
                      )
                    }
                  >
                    {address}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{name}</TableCell>
                  <TableCell className={classes.tableCell}>{hpio}</TableCell>
                  <TableCell
                    className={`${classes.tableCell} ${classes.link}`}
                    onClick={() => window.open(url, "_blank")}
                  >
                    {url}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{details}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      variant="text"
                      color="primary"
                      style={{ display: "flex", whiteSpace: "nowrap" }}
                      onClick={() => openPlanDialog(address)}
                    >
                      View Plan
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton
                        aria-label="delete"
                        disabled={removing}
                        className={removing === address ? "rotate" : ""}
                        onClick={() => _removeUser(address, name)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        disabled={removing}
                        className={removing === address ? "rotate" : ""}
                        onClick={() => editPlan(address)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Plan Details Dialog */}
      <Dialog
        open={openDialog}
        onClose={closePlanDialog}
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.dialogHeader}>
          <DialogTitle>{`User Plan Details`}</DialogTitle>
          <IconButton aria-label="close" onClick={closePlanDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {selectedPlan ? (
            <>
              <p>
                <strong>User Id : </strong>
                {selectedPlan.userId || "-"}
              </p>
              <p>
                <strong>Plan Name :</strong> {selectedPlan.planName || "-"}
              </p>
              <p>
                <strong>Device Limit :</strong> {selectedPlan.maxDevice || "-"}
              </p>
              <p>
                <strong>Validity Period :&nbsp;</strong>
                {selectedPlan.timeDuration
                  ? selectedPlan.timeDuration + " Year"
                  : "-"}
              </p>
              <p>
                <strong>createdTime : </strong>
                {/* {new Date(selectedPlan.createdTime * 1000).toLocaleString() ||
                  "-"} */}
                {moment.unix(selectedPlan.createdTime).format("MM/DD/YYYY hh:mm:ss A") || "-"}
              </p>
              <p>
                <strong>expirationTime :  </strong>
                {moment.unix(selectedPlan.expirationTime).format("MM/DD/YYYY hh:mm:ss A") || "-"}
              </p>
            </>
          ) : (
            <p>No data found for the selected user.</p>
          )}
        </DialogContent>
      </Dialog>

      {/* edit Plan Details Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={closePlanDialog}
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.dialogHeader}>
          <DialogTitle>{`Edit Plan Details`}</DialogTitle>
          <IconButton aria-label="close" onClick={closePlanDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel id="plan-label">Select Plan</InputLabel>
            <Select
              labelId="plan-label"
              id="plan"
              value={selectedPlan || ""}
              onChange={(event) => setSelectedPlan(event.target.value)}
              style={{ width: "100%", marginRight: "8rem" }}
              renderValue={(p) => p.planName}
            >
              {plans.map((plan, index) => (
                <MenuItem key={index} value={plan}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold" }}>{plan.planName}</span>
                    <span>Device Limit: {plan.maxDevice}</span>
                    <span>Expiry: {plan.timeDuration} Year</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* {selectedUserPlan && (
            <>
              <h4>Current Selected Plan Details:</h4>
              <p>
                <strong>Plan Name :</strong> {selectedUserPlan.planName || "-"}
              </p>
              <p>
                <strong>Device Limit :</strong>{" "}
                {selectedUserPlan.maxDevice || "-"}
              </p>
              <p>
                <strong>Validity Period :&nbsp;</strong>
                {selectedUserPlan.timeDuration
                  ? selectedUserPlan.timeDuration + " Month"
                  : "-"}
              </p>
            </>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={closePlanDialog} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => saveUserPlan(selectedUserPlan.userId)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default withStyles(styles)(UserTable);