import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  disclaimerAccepted: false,
  openDialog: false,
  myDeviceId: null,
  maxCount: 0,
  deviceNames: [],
  heading: "MediSend",
  ethereumAccount: null,
  showUploader: false,
  showChat: false,
  chatHistory: {},
  activeClientList: [],
  userLedger: {},
  transferLedger: {},
  mute: false,
  isLoggedInAsAdmin: false,
  selectedClient: null,
  socket: null,
  proposal: null,
  fileDownloadObj: null,
  ethereumTxnBeingMined: null,
  confirm: false,
  loading: true,
  notification: null,
  freeze: false,
  nwBlip: false,
  missedCalls: [],
  searchText: "",
  systemState: "idle", // { sender : idle, proposed, uploading, receiver : deciding, waitingForDownload, downloading  }
};

export const {
  GlobalStateProvider,
  useGlobalState,
  getGlobalState,
  setGlobalState,
} = createGlobalState(initialState);