import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SERVER_URL } from "../../config";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { downloadFile, getBackground } from "../../util";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Preview from "../client/Preview";
import { TextField } from "@material-ui/core";
import { useGlobalState } from "../../store";
import Notification from "../common/Notification";

const useStyles = makeStyles((theme) =>
  createStyles({
    summary: {
      marginTop: "6rem",
      display: "flex",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      width: "100%",
    },
    verify: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      gap: 15,
    },
    buttons: {
      margin: "1rem 0 4rem 0",
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
    },
    info: {
      width: "40%",
    },
    link: {
      textDecoration: "underline",
      "&:hover": {
        color: "#cc0000",
        cursor: "pointer",
      },
    },
  })
);

export default function NonMedisend() {
  const classes = useStyles();
  const { search } = useLocation();
  const [objectURL, setobjectURL] = useState("");
  const [code, setCode] = useState();
  const [isVerified, setVerified] = useState(false);
  const [isFailed, setFailed] = useState(false);

  const [, setNotification] = useGlobalState("notification");
  // Get filename parameter
  const searchParams = new URLSearchParams(search); // Not supported in IE
  const filename = searchParams.get("filename");
  const uuid = searchParams.get("uuid");
  const url = `${SERVER_URL}/download?file=${filename}&type=${searchParams.get(
    "type"
  )}`;

  const getObjectURL = async () => {
    const res = await fetch(url);
    console.log("🚀 ~ file: NonMedisend.js:70 ~ getObjectURL ~ res:", res);
    if (res.status === 200) {
      setobjectURL(window.URL.createObjectURL(await res.blob()));
    } else {
      setFailed(true);
    }
  };
  // Fetch file when page loads and generate blob url
  useEffect(() => {
    try {
      getObjectURL();
    } catch (err) {
      setFailed(true);
      console.log(err);
    }
    // eslint-disable-next-line
  }, []);

  const verifyOtp = async () => {
    // const response = await fetch(`${SERVER_URL}/verifyOtp`, {
    //   method: "post",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ code, uuid }),
    // });
    // const jsonResponse = await response.json();
    try {
      const response = await fetch(`${SERVER_URL}/verifyOtp`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, uuid }),
      });
      const { isValid, message } = await response.json();

      if (isValid) {
        setVerified(true);
      } else {
        setNotification({
          type: "error",
          text: message,
        });
      }
    } catch (e) {
      console.log("🚀 ~ file: NonMedisend.js:122 ~ verifyOtp ~ e:", e);
      setFailed(true);
      setNotification({
        type: "error",
        text: "Failed to generate one-time passcode",
      });
    }
  };
  return (
    <div>
      <AppBar
        position="fixed"
        style={{ background: getBackground("nonmedisend"), top: 0 }}
      >
        <Toolbar>
          {
            <img
              src="logo.png"
              alt="x"
              style={{ width: "2rem", height: "2rem", marginRight: "1rem" }}
            />
          }
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`Download file`}
          </Typography>
        </Toolbar>
      </AppBar>
      {searchParams.get("type") === "application/pdf" && (
        <div className={classes.summary}>
          <div style={{ margin: "3rem 0 2rem 0" }}>
            <Preview file={objectURL} type={searchParams.get("type")} />
            {/* console.log({objectURL}) */}
          </div>
        </div>
      )}
      {isVerified ? (
        isFailed ? (
          <div className={classes.summary}>
            <p>Your Link is Expired</p>
          </div>
        ) : (
          <>
            {searchParams.get("type") !== "application/pdf" && (
              <div className={classes.summary}>
                <div style={{ margin: "3rem 0 2rem 0" }}>
                  <Preview file={objectURL} type={searchParams.get("type")} />
                </div>
              </div>
            )}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "10rem" }}
                onClick={() =>
                  downloadFile(
                    objectURL,
                    filename.split("_")[2] +
                      "." +
                      searchParams.get("type").split("/")[1]
                  )
                }
              >
                Download
              </Button>
            </div>
          </>
        )
      ) : (
        <div
          className={classes.verify}
          style={{
            marginTop:
              searchParams.get("type") === "application/pdf" ? 0 : "6rem",
          }}
        >
          <TextField
            variant="outlined"
            label={"OTP :"}
            value={code || ""}
            onChange={(e) => setCode(e.target.value)}
            className={classes.textBox}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!code}
            style={{ verticalAlign: "bottom" }}
            onClick={() => verifyOtp()}
          >
            Send
          </Button>
        </div>
      )}
      <Notification />
    </div>
  );
}
