import { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DropzoneArea } from "material-ui-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";

import { sendProposalAndWait } from "../../socket";
import { useGlobalState } from "../../store";
import { SERVER_URL } from "../../config";
import {
  gDate,
  addWaitingTransaction,
  // sendEmail,
  sendFileDownloadLinkEmail,
  generateUUID,
} from "../../util";

import Preview from "./Preview";
import axios from "axios";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      borderBottom: "1px solid #e0e0e0",
    },
    loading: {
      position: "relative",
      margin: "2rem 20% 0 -40%",
    },
    connect: {
      height: "1.9rem",
      width: "1.9rem",
      marginRight: "0.5rem",
      bottom: "0",
      right: "calc(50% - 3.3rem)",
      position: "absolute",
      borderRadius: "50%",
      padding: "2px",
    },
    upload: {
      height: "1rem",
      width: "1rem",
      marginRight: "0.5rem",
      bottom: "0.3rem",
      right: "calc(50% - 5.2rem)",
      position: "absolute",
      borderRadius: "50%",
      padding: "2px",
      border: "2px solid #E74C3C",
    },
    inputBox: {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: 15,
    },
    progress: {
      bottom: "0.9rem",
      right: "1.5rem",
      width: "calc(50% - 7rem)",
      position: "absolute",
      borderRadius: "1rem",
    },
    dropzone: {
      margin: "3rem auto",
      width: "60%",
      color: "#333333",
      opacity: 1,
    },
    radio: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      cursor: "pointer",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      padding: "0 10%",
      alignItems: "center",
      alignContent: "space-between",
    },
    textBox: { width: "10rem" },
    close: {
      position: "absolute",
      top: "0.5rem",
      right: "0.5rem",
      transform: "scale(0.5)",
    },
    delete: {
      position: "absolute",
      top: "6.5rem",
      zIndex: 1,
      marginLeft: "0.5rem",
      boxShadow: "none",
    },
  })
);

const INIT = 0,
  PROPOSED = 1,
  UPLOADING = 2;

const initialState = {
  fromName: "",
  toName: "",
  patientName: "",
  details: "",
  mobileNumber: undefined,
  countryCode: undefined,
  sendOtp: false,
  fax: false,
};
export default function FileUploader() {
  const classes = useStyles();
  const [uploadingStage, setUploadingStage] = useState(INIT);
  const [file, setFile] = useState(null);

  const [, setNotification] = useGlobalState("notification");
  const [selectedClient] = useGlobalState("selectedClient");
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [userLedger] = useGlobalState("userLedger");
  const [, setSystemState] = useGlobalState("systemState");
  const [showUploader, setShowUploader] = useGlobalState("showUploader");
  const [metadata, setMetadata] = useState(initialState);
  const [patientDOB, setPatientDOB] = useState();
  const [open, setOpen] = useState();
  const [link, setLink] = useState();
  const [code, setCode] = useState();
  const [sendMethod, setSendMethod] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleLinkClose = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link);
      // alert('Link copied to clipboard!');
    } else {
      console.log("Copying to clipboard is not supported in this environment.");
    }
  };
  const handleOtpClose = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(code);
      // alert('OTP copied to clipboard!');
    } else {
      console.log("Copying to clipboard is not supported in this environment.");
    }
  };
  const timestamp = () => {
    const ts = new Date();
    return `${ts.getDate()}-${ts.getMonth() + 1
      }-${ts.getHours()}-${ts.getMinutes()}`;
  };

  // Upload file
  const doUpload = async (file, metadata, nonMedisend) => {
    try {
      const data = new FormData();
      data.append("file", file);
      data.append(
        "metadata",
        JSON.stringify({
          ...metadata,
          fileType: file.type,
          patientDOB: gDate(patientDOB),
        })
      );
      await fetch(`${SERVER_URL}/upload`, {
        method: "post",
        body: data,
        headers: {},
      });

      if (!nonMedisend) {
        setNotification({
          type: "success",
          text: `Waiting for ${userLedger[metadata.toAccountId].name
            } to download file for ${metadata.patientName}`,
        });
      }
    } catch (e) {
      setNotification({
        type: "error",
        text: "File could not be transfered to server",
      });
    }
  };
  const {
    fromName,
    toName,
    patientName,
    mobileNumber,
    countryCode,
    sendOtp,
    fax,
    details,
    faxNumber,
  } = metadata;

  const fetchOTP = async (filename) => {
    try {
      let data = { filename };
      if (sendOtp) {
        data.countryCode = countryCode;
        data.mobileNumber = mobileNumber;
      }
      const response = await fetch(`${SERVER_URL}/otp`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (e) {
      setNotification({
        type: "error",
        text: "Failed to generate one-time passcode",
      });
    }
  };

  const sendfax = async (filename, file) => {
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("faxNumber", faxNumber);
      data.append("toName", toName)
      data.append("fromName", fromName)
      const response = await axios.post(`${SERVER_URL}/fax`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          accesstoken: process.env.IFAX_LIVE_API_KEY,
        },
      });
      if (response) {
        setNotification({
          type: "success",
          text: "Send fax SuccessFully...",
        });
      } else {
        setNotification({
          type: "error",
          text: response.data.message,
        });
      }
    } catch (error) {
      setNotification({
        type: "error",
        text: error.response ? error.response.data.message : error.message,
      });
    }
  };
  const shorturl = async (url) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/url`,
        { url },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsonResponse = response.data.data;
      console.log("response in url", jsonResponse);
      var short = jsonResponse.tiny_url;
      return short;
    } catch (error) {
      console.error("Error occurred while shortening URL:", error);
    }
  };

  const doSend = async (nonMedisend) => {
    try {
      if (sendOtp && !countryCode?.includes("+")) {
        setNotification({
          type: "error",
          text: "Invalid Country Code",
        });
        return;
      }
      if (fax && !faxNumber?.includes("+")) {
        setNotification({
          type: "error",
          text: "Invalid fax Number",
        });
        return;
      }
      // Set status to proposed
      let proposal = { proposedId: "", acceptedId: "" };
      let emailLink = "";
      const filename = `${patientName}-${gDate(patientDOB)}-${timestamp()}`;
      setUploadingStage(PROPOSED);
      setSystemState("proposed");

      if (nonMedisend) {
        // If sending to non-medisend, use random uuid (mitigate direct file download from /download endpoint)
        const randomUUID = generateUUID();
        proposal = { proposedId: randomUUID, acceptedId: randomUUID };
        emailLink = `${window.location}nonmedisend?filename=${proposal.proposedId}_${proposal.acceptedId}_${filename}`;
        // sendEmail(
        //   "",
        //   "Medisend from",
        //   `Please click link to download your medical document and then enter the patient date of birth.\n Link to file: ${link}\n World's first peer-to-peer file transfer app, where the integrity and history of transactions is guaranteed on Ethereum, world's largest public blockchain. Apply to participate now. https://www.medisend.com.au.\n This email and any attachments may be confidential. If received in error, please contact the sending organisation. Before opening or using attachment,s check them for viruses and defects. Regardless of any loss, damage or consequence, whether caused by the negligence of the sender or not, resulting directly or indirectly from the use of any attached files our liability is limited to resupplying any affected attachments. Any representations or opinions expressed are those of the individual sender, and not necessarily those of Medisend.`
        // );
      } else {
        // Propose file transfer for medisend users
        proposal = await sendProposalAndWait({
          toAccountId: selectedClient,
          ...metadata,
          patientDOB: gDate(patientDOB),
        });
      }
      // Set status to uploading
      setShowUploader(false);
      setSystemState("uploading");
      setUploadingStage(UPLOADING);

      // Upload file and metadata
      await doUpload(
        file,
        {
          proposedId: proposal.proposedId,
          acceptedId: proposal.acceptedId,
          fromAccountId: ethereumAccount,
          toAccountId: selectedClient,
          targetFileName: filename,
          patientName,
          patientDOB,
        },
        nonMedisend
      );

      // Set status to idle
      setSystemState("idle");

      if (!nonMedisend) {
        // Add pending transaction for medisend users
        addWaitingTransaction(
          ethereumAccount,
          selectedClient,
          filename,
          file.size
        );
      } else {
        if (fax) {
          await sendfax(filename, file);
        } else {
          const { code, uuid } = await fetchOTP(filename);
          setCode(code);
          setLink(`${emailLink}&uuid=${uuid}&type=${file?.type}`);
          const shortedLink = await shorturl (`${emailLink}&uuid=${uuid}&type=${file?.type}`);
          // console.log("shortedLink11",shortedLink);
          sendFileDownloadLinkEmail(
            ``,
            `One time download from Medisend for ${patientName.trim()}`,
            encodeURIComponent(`Hi ${toName.trim()},\n\nPlease see link to download file for ${patientName.trim()}. Click this ${shortedLink} and enter the one time password that was sent to you. Please note the link will expire shorlty. If it has expired, please request the file again from the sender.\n\nIf you have issues downloading the linked file please contact support@medisend.com.au\n\nMedisend - Secure medical records transfer\n\nWorld's first peer-to-peer file transfer app, where the integrity and history of transactions is guaranteed on Ethereum, world's largest public blockchain. Apply to participate in a limited Beta now. https://www.medisend.com.au`),
          )
          handleOpen();
        }
      }
      // Reset
      setUploadingStage(INIT);
      reset();
    } catch (e) {
      // Log errors
      console.error(e);
      setSystemState("idle");
      setUploadingStage(INIT);
    }
  };

  const reset = () => {
    setFile(null);
    setMetadata({ ...initialState });
    setPatientDOB(null);
    setShowUploader(false);
    setSendMethod(null)
  };

  return (
    <Collapse in={showUploader} className={classes.root}>
      {!file && (
        <div className={classes.dropzone}>
          <DropzoneArea
            filesLimit={1}
            acceptedFiles={["image/jpeg", "application/pdf", "text/xml"]}
            cancelButtonText={"cancel"}
            submitButtonText={"submit"}
            maxFileSize={50000000}
            showPreviews={false}
            showpatientNamesInPreview={false}
            showAlerts={["error", "info"]}
            onChange={(files) => setFile(files[0])}
            disabled={uploadingStage >= 0}
          />
        </div>
      )}
      {file && (
        <div style={{ margin: "3rem 0 2rem 0" }}>
          <Fab
            color="default"
            className={classes.delete}
            size="small"
            onClick={() => setFile(null)}
          >
            <DeleteIcon />
          </Fab>
          <Preview file={file} type={file.type} />
        </div>
      )}
      <form
        className={classes.form}
        onChange={({ target }) => {
          const _metadata = { ...metadata };

          if (target.id === "sendOtp") {
            _metadata["sendOtp"] = true;
            _metadata["fax"] = false;
            setSendMethod("sendOtp");
          } else if (target.id === "fax") {
            _metadata["sendOtp"] = false;
            _metadata["fax"] = true;
            setSendMethod("fax");
          } else {
            _metadata[target.id] = target.value;
          }
          setMetadata(_metadata);
        }}
      >
        <Fab color="secondary" className={classes.close} onClick={reset}>
          <CloseIcon />
        </Fab>
        <div className={classes.inputBox}>
          <TextField
            id="fromName"
            label={"From (Name)"}
            value={fromName || ""}
            className={classes.textBox}
            disabled={uploadingStage > INIT}
          />
          <TextField
            id="toName"
            label={"To (Name)"}
            value={toName || ""}
            className={classes.textBox}
            disabled={uploadingStage > INIT}
          />
          <TextField
            id="patientName"
            label={"Patient Name ⁺"}
            value={patientName || ""}
            className={classes.textBox}
            disabled={uploadingStage > INIT}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              id="patientDOB"
              label="Patient DOB"
              format="dd/MM/yyyy"
              value={patientDOB}
              onChange={(date) => setPatientDOB(date)}
              disabled={uploadingStage > INIT}
            />
          </MuiPickersUtilsProvider>
          {selectedClient === "Non-Medisend" && (
            <TextField
              id="countryCode"
              label={"Country Code"}
              value={countryCode || ""}
              className={classes.textBox}
              disabled={uploadingStage > INIT || !sendOtp}
            />
          )}
          {selectedClient === "Non-Medisend" && (
            <TextField
              id="mobileNumber"
              label={"Mobile No."}
              value={mobileNumber || ""}
              className={classes.textBox}
              disabled={uploadingStage > INIT || !sendOtp}
            />
          )}
          {selectedClient === "Non-Medisend" && (
            <TextField
              id="faxNumber"
              label={"Fax No."}
              value={faxNumber || ""}
              className={classes.textBox}
              disabled={uploadingStage > INIT || !fax}
            />
          )}
        </div>
        {selectedClient === "Non-Medisend" && (
          <div className={classes.radio}>
            <Radio
              id="sendOtp"
              checked={sendMethod === "sendOtp"}
              value="sendOtp"
            />
            <label htmlFor="sendOtp" className={classes.label}>
              Send OTP?
            </label>
            <Radio id="fax" checked={sendMethod === "fax"} value="fax" />
            <label htmlFor="fax" className={classes.label}>
              Send Fax?
            </label>
          </div>
        )}
        {selectedClient !== "Non-Medisend" && (
          <div style={{ display: "flex", width: "100%", gap: "25px" }}>
            <TextField
              id="details"
              label={"Subject"}
              value={details}
              className={classes.textBox}
              style={{ width: "calc(100% - 7rem)" }}
              disabled={uploadingStage > INIT}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={
                uploadingStage > INIT || !file || !patientDOB || !patientName
              }
              style={{ verticalAlign: "bottom" }}
              onClick={() => doSend(false)}
            >
              Send
            </Button>
          </div>
        )}
        {selectedClient === "Non-Medisend" && ( // Replace with file
          <Button
            variant="contained"
            color="primary"
            disabled={
              uploadingStage > INIT || !file || !patientDOB || !patientName
            }
            style={{ verticalAlign: "bottom" }}
            onClick={() => doSend(true)}
          >
            Send
          </Button>
        )}
      </form>
      <div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open}
          onClose={() => { }}
        >
          <DialogContent>
            <div
              className={classes.dialogHeader}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </div>
            <h1 style={{ textAlign: "center" }}>{code}</h1>
            <p>Please send this passcode to the non-medisend recipient.</p>
            {/* <strong>
              <p style={{ textAlign: "center" }}>Do not use email.</p>
            </strong> */}
          </DialogContent>
          <DialogActions>
            {link && (
              <Button autoFocus onClick={handleLinkClose} color="primary">
                Copy Link
              </Button>
            )}
            <Button autoFocus onClick={handleOtpClose} color="primary">
              Copy OTP
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div
        className={classes.loading}
        style={{ opacity: uploadingStage > INIT ? 1 : 0 }}
      >
        <img
          alt="connect"
          src={"connect.png"}
          className={
            classes.connect + (uploadingStage === PROPOSED ? " rotate" : "")
          }
        />
        <img
          alt="upload"
          src={"upload.png"}
          className={
            classes.upload + (uploadingStage === UPLOADING ? " rotate" : "")
          }
        />
        <LinearProgress className={classes.progress} color={"primary"} />
      </div>
    </Collapse>
  );
}
