//import Loader from "react-loader-spinner";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useGlobalState } from "../../store";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100px",
      height: "100px",
      position: "fixed",
      top: "50%",
      left: "50%",
      marginTop: "-50px",
      marginLeft: "-50px",
    },
  })
);

export default function Loading() {
  const [loading] = useGlobalState("loading");
  const classes = useStyles();
  return (
    <div>
      {loading && (
        <img className={`${classes.root} rotate`} src="logo2.png" alt="x" />
      )}
      {/*loading && ( <Loader type="Bars" color="#00BFFF" height={100} width={100} />)*/}
    </div>
  );
}
