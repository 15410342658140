import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useGlobalState } from "../../store";
import { PEER_TIMEOUT_SEC } from "../../config";

/** Usage: setConfirm({ show: true, text: `Would you like to email an invoice?`, ok: doInvoice, }); */
export default function Confirm() {
  const [confirm, setConfirm] = useGlobalState("confirm");
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!confirm.show) return;
    const _timer = setTimeout(() => {
      confirm.cancel && confirm.cancel();
      setConfirm({
        show: false,
        text: "",
      });
    }, PEER_TIMEOUT_SEC * 1000);
    setTimer(_timer);
  }, [setTimer, setConfirm, confirm]);

  const reset = () => {
    setConfirm({
      show: false,
      text: "",
    });
    clearTimeout(timer);
  };
  const onClose = () => {
    reset();
    confirm.cancel && confirm.cancel();
  };

  return (
    <Dialog
      open={Boolean(confirm.show)}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {confirm.icon && (
          <img
            src={confirm.icon}
            alt="c"
            style={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              width: "2rem",
            }}
          />
        )}
        <DialogContentText
          id="alert-dialog-description"
          dangerouslySetInnerHTML={{ __html: confirm.text }}
          style={{ margin: "1rem 1rem 0 1rem" }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            confirm.cancel && confirm.cancel();
            reset();
          }}
          color="primary"
        >
          {"Cancel"}
        </Button>
        <Button
          onClick={() => {
            confirm.ok && confirm.ok();
            reset();
          }}
          color="primary"
          autoFocus
        >
          {"Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
