import { createStyles, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";

import { useGlobalState } from "../../store";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      borderRadius: "4px",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing(6),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: "0.5",
    },
    clearIcon: {
      position: "absolute",
      color: "white",
      top: 0,
      right: 0,
      padding: "4.5px",
      transform: "scale(0.75)",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(6),
      marginRight: "1.5rem",
      transition: theme.transitions.create("width"),
      width: "4rem",
      "&:hover": {
        width: "8rem",
      },
    },
  })
);

export default function Freeze() {
  const [searchText, setSearchText] = useGlobalState("searchText");
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search …"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <IconButton
        className={classes.clearIcon}
        onClick={() => setSearchText("")}
        disabled={searchText === ""}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
}
