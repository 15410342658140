import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  ListItem,
  ListItemText,
  List,
  Divider,
} from "@material-ui/core";
import { useGlobalState } from "../../store";
import { SERVER_URL } from "../../config";
import { initBlockchain } from "../../blockchain";
import image from "../../assets/images/undraw_in_sync_re_jlqd.svg";
import io from "socket.io-client";

const socket = io(SERVER_URL);

function DialogComponent({ dialog }) {
  const [openDialog, setOpenDialog] = useGlobalState("openDialog");
  const [maxCount] = useGlobalState("maxCount");

  const handleClose = (event, reason) => {
    // Do not close the dialog if the click event originated from the backdrop
    if (reason === "backdropClick") {
      return;
    }
    setOpenDialog(false);
  };

  const handleLogout = async (deviceId) => {
    console.log("handle logout deviceid~~", deviceId);
    const response = await fetch(
      `${SERVER_URL}/deviceId?deviceId=${deviceId}`,
      {
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          deviceId: deviceId,
        }),
      }
    );
    const jsonResponse = await response.json();
    if (jsonResponse.status === 200) {
      handleClose();
      if (socket) {
        socket.emit("logout-notification", deviceId);
        await initBlockchain();
      } else {
        console.log("Socket not established");
      }
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent style={{ padding: 20 }}>
          <div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                lg={6}
                md={6}
                style={{ textAlign: "center", alignItems: "center" }}
              >
                <div>
                  <img src={image} alt="x" width={"50%"} />
                  <h4 style={{ marginBlockEnd: "10px" }}>
                    Login Pending, Device Limit Reached
                  </h4>
                  <p style={{ marginTop: 0 }}>
                    Your current plan supports {maxCount} Devices only
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <h3 style={{ textAlign: "center", marginBlockStart: 0 }}>
                  Log Out 1 Device to Continue
                </h3>
                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <List>
                    {dialog?.map((device, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          button
                          style={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          <ListItemText
                            primary={device.deviceName}
                          // secondary={`Last Used: ${new Date(
                          //   device.lastConnectionDate * 1000
                          // ).toLocaleString()}`}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            className="cursor"
                            onClick={() => handleLogout(device?.deviceId)}
                          >
                            Log out{" "}
                          </Button>
                        </ListItem>
                        {index < dialog.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </List>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DialogComponent;