import "./App.css";
import { useEffect } from "react";
import Loading from "./components/common/Loading";
import Navigation from "./components/common/Navigation";
import ClientPage from "./components/client/ClientPage";
import AdminPage from "./components/admin/AdminPage";
import Notification from "./components/common/Notification";
import Confirm from "./components/common/Confirm";
import Freeze from "./components/common/Freeze";
import SocialSharing from "./components/client/SocialSharing";
import Chat from "./components/client/Chat";
import Disclaimer from "./components/client/Disclaimer";
import { initBlockchain } from "./blockchain";
import { useGlobalState } from "./store";
import { openSocket } from "./socket";
import DialogComponent from "./components/client/dialog";
initBlockchain();

function App() {
  const [isLoggedInAsAdmin] = useGlobalState("isLoggedInAsAdmin");
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [loading] = useGlobalState("loading");
  const [selectedClient] = useGlobalState("selectedClient");
  const [fileDownloadObj] = useGlobalState("fileDownloadObj");
  const [deviceNames] = useGlobalState("deviceNames");

  useEffect(() => {
    if (ethereumAccount) setTimeout(() => openSocket(), 100);
  }, [ethereumAccount]);

  return (
    <div className="App">
      {ethereumAccount && <Navigation />}
      {ethereumAccount && isLoggedInAsAdmin && <AdminPage />}
      {ethereumAccount && !isLoggedInAsAdmin && !loading && <ClientPage />}
      {selectedClient && <Chat />}
      <Loading />
      <Notification />
      <Confirm />
      <Freeze />
      <Disclaimer />
      {!fileDownloadObj && <SocialSharing />}
      <DialogComponent dialog={deviceNames} />
    </div>
  );
}

export default App;