import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NonMedisend from "./components/nonmedisend/NonMedisend";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/nonmedisend" element={<NonMedisend />}></Route>
        <Route path="/" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  </MuiThemeProvider>,
  document.getElementById("root")
);
serviceWorker.register();
reportWebVitals();