import { CSVLink } from "react-csv";

import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@mdi/react";
import { Chart } from "react-google-charts";
import { mdiMicrosoftExcel, mdiFaceAgent } from "@mdi/js";
import TransactionHistory from "./TransactionHistory";
import { useGlobalState } from "../../store";
import { fDate, fBytes, getBackground, sendEmail } from "../../util";
import { ETHERSCAN_URL, TUTORIAL_URL } from "../../config";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "96%",
      margin: "2rem auto",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
      opacity: 0.7,
    },
    summary: {
      display: "flex",
    },
    title: {
      display: "inline-block",
      width: "25rem",
      textAlign: "left",
      marginLeft: "3rem",
    },
    total: {
      display: "inline-block",
      width: "8rem",
      textAlign: "left",
    },
    icon: {
      width: "1.5rem",
      marginRight: "1rem",
      display: "inline-block",
    },
    green: {
      display: "inline-block",
      borderRadius: "50%",
      background: "green",
      width: "1rem",
      height: "1rem",
      margin: "0 1.25rem 0 0.25rem",
      verticalAlign: "text-bottom",
    },
    grey: {
      display: "inline-block",
      borderRadius: "50%",
      background: "grey",
      width: "1rem",
      height: "1rem",
      margin: "0 1.25rem 0 0.25rem",
      verticalAlign: "text-bottom",
    },
    download: {
      width: "3rem",
      height: "3rem",
      position: "fixed",
      bottom: "1rem",
      right: "1rem",
    },
    chart: { height: "20rem" },
    ldr: {
      width: "2rem",
      height: "2rem",
    },
    videos: {
      marginTop: "4rem",
      width: "560px",
      height: "315px",
    },
  })
);

const sentColor = `rgb(55,125,34)`;
const receivedColor = `rgb(234,50,35)`;
const totalColor = `rgb(83,132,235)`;

export default function Analytics() {
  const classes = useStyles();
  const [activeClientList] = useGlobalState("activeClientList");
  const [userLedger] = useGlobalState("userLedger");
  const [transferLedger] = useGlobalState("transferLedger");
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [fTransferLedger, setFTransferLedger] = useState({});
  const [filter, setFilter] = useState("1 Hour");
  const [chartData, setChartData] = useState([]);
  const [expanded, setExpanded] = useState(true);

  const csvData = Object.values(fTransferLedger)
    .flatMap((a) => a)
    .map(({ _timestamp, from, to, _name, _size, transactionHash }) => {
      return {
        date: fDate(_timestamp),
        sender: from,
        receiver: to,
        filename: _name,
        size: `${_size}B (${fBytes(_size)})`,
        "Ethereum Link": `${ETHERSCAN_URL}/tx/${transactionHash}`,
      };
    });

  useEffect(() => {
    const _transferLedger = {};
    let threshold = Date.now() / 1000;
    if (filter === "1 Hour") threshold -= 60 * 60;
    else if (filter === "1 Day") threshold -= 24 * 60 * 60;
    else threshold -= 7 * 24 * 60 * 60;
    Object.entries(transferLedger).forEach(([client, history]) => {
      const _history = history.filter(
        ({ _timestamp }) => _timestamp > threshold
      );
      if (_history.length <= 0) return;
      _transferLedger[client] = _history;
    });
    setFTransferLedger(_transferLedger);
  }, [transferLedger, filter, ethereumAccount]);

  useEffect(() => {
    // first we combine all events into one big array and sort it with latest event in the front
    const allEvents = Object.values(transferLedger)
      .flatMap((a) => a)
      .sort((e1, e2) => e2._timestamp - e1._timestamp);
    const _chartData = [];
    // next we start from the latest event and produce our events array
    let startOfDay = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);
    let sent = 0;
    let received = 0;
    const gd = (utime) => {
      const date = new Date(utime * 1000);
      return `${date.getDate()}/${date.getMonth() + 1}`;
    };
    for (let i = 0; i < allEvents.length; i++) {
      const event = allEvents[i];
      // if this event happened before the startOfTheDay, we conclude our search for this day and move startOfDay back by a day
      if (event._timestamp < startOfDay) {
        _chartData.unshift([gd(startOfDay), sent + received, sent, received]);
        sent = received = 0;
        // now we go back a day
        startOfDay -= 24 * 60 * 60;
        // and we keep on going back a day, and everytime we do so, we produce an 0 usage
        while (event._timestamp < startOfDay) {
          _chartData.unshift([gd(startOfDay), 0, 0, 0]);
          startOfDay -= 24 * 60 * 60;
        }
      }
      // since this event happened after our startOfDay, we modify our counters to reflect whether its sent or received event
      if (event._timestamp >= startOfDay) {
        if (event._from === ethereumAccount) sent += 1;
        else received += 1;
      }
    }
    // as we exit the loop, we add one final entry if it hant been added
    if (sent + received > 0)
      _chartData.unshift([gd(startOfDay), sent + received, sent, received]);
    // finally, we add title and set the graph up
    _chartData.unshift(["", "Total", "Sent", "Received"]);
    setChartData(_chartData);
  }, [transferLedger, ethereumAccount]);

  const stats = {};
  Object.entries(fTransferLedger).forEach(([client, history]) => {
    const _stats = { sent: 0, received: 0 };
    history.forEach(({ _from }) => {
      if (_from === ethereumAccount) _stats.sent += 1;
      else _stats.received += 1;
    });
    stats[client] = _stats;
  });

  if (chartData.length <= 1)
    return (
      <div className={classes.root}>
        <Typography
          variant="h5"
          style={{ margin: "6rem 0 2rem 0" }}
        >{`Welcome to MediSend`}</Typography>
        <Typography variant="body2">
          {`Looks like this is your first time to use MediSend. Watch the
          following Video tutorial to get started.`}
        </Typography>
        <Typography variant="body2" style={{ margin: "1rem 0" }}>
          {`Press the`}
          <IconButton
            color="inherit"
            onClick={() => sendEmail("support@medisend.com.au", "Medisend support message from", "Please tell us about your complaint or compliment.")}
            style={{ verticalAlign: "middle" }}
          >
            <Icon path={mdiFaceAgent} size={1} />
          </IconButton>
          {`button to get assitance.`}
        </Typography>
        <iframe
          className={classes.videos}
          src={TUTORIAL_URL}
          title="MediSend tutorial"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );

  return (
    <div className={classes.root}>
      {chartData && (
        <Accordion style={{ marginBottom: "3rem" }} expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            <Typography
              variant="h6"
              style={{ opacity: 0.7, marginLeft: "1rem" }}
            >
              MediSend Daily usage
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ padding: "0 1rem", width: "calc(100% - 2rem)" }}>
              <Chart
                className={classes.chart}
                chartType="Bar"
                loader={
                  <img
                    className={classes.ldr + ` rotate`}
                    src="logo2.png"
                    alt="x"
                  />
                }
                data={chartData}
                options={{
                  colors: [totalColor, sentColor, receivedColor],
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      <ButtonGroup color="secondary" style={{ marginBottom: "2rem" }}>
        {["1 Hour", "1 Day", "1 Week"].map((text) => (
          <Button
            key={text}
            variant={filter === text ? "contained" : "outlined"}
            style={{ width: "6rem" }}
            onClick={() => setFilter(text)}
          >
            {text}
          </Button>
        ))}
      </ButtonGroup>
      {Object.values(fTransferLedger).flatMap((a) => a).length <= 0 && (
        <div>
          <Typography
            variant="caption"
            style={{ opacity: 0.7 }}
          >{`There were no transfers in the past ${
            filter.toLowerCase().split(" ")[1]
          }`}</Typography>
        </div>
      )}
      {Object.entries(userLedger).map(([client, { name, icon }]) => {
        if (!fTransferLedger[client]) return <div key={client}></div>;
        const online = activeClientList.includes(client);
        return (
          <Accordion key={client}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.summary}>
                {icon ? (
                  <img src={icon} alt="c" className={classes.icon} />
                ) : (
                  <div className={online ? classes.green : classes.grey} />
                )}
                <div className={classes.title}>
                  <Typography className={classes.heading}>{name}</Typography>
                </div>
                <div className={classes.total}>
                  <Typography
                    style={{ color: totalColor, fontWeight: "bold" }}
                  >{`Total: ${
                    stats[client].sent + stats[client].received
                  }`}</Typography>
                </div>
                <div className={classes.total}>
                  <Typography
                    style={{ color: sentColor, fontWeight: "bold" }}
                  >{`Sent: ${stats[client].sent}`}</Typography>
                </div>
                <div className={classes.total}>
                  <Typography
                    style={{ color: receivedColor, fontWeight: "bold" }}
                  >{`Received: ${stats[client].received}`}</Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                <TransactionHistory
                  selectedClient={client}
                  hideDownload={true}
                  transactionHistory={fTransferLedger[client]}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {Object.keys(fTransferLedger).length > 0 && (
        <CSVLink
          data={csvData}
          filename={`Past ${filter} Transfer-log for ${
            userLedger[ethereumAccount].name
          } at ${fDate()}.csv`}
        >
          <Fab
            className={classes.download}
            color={"primary"}
            aria-label="download"
            style={{ background: getBackground(ethereumAccount) }}
          >
            <Icon path={mdiMicrosoftExcel} size={1} />
          </Fab>
        </CSVLink>
      )}
    </div>
  );
}
