import { CSVLink } from "react-csv";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fab from "@material-ui/core/Fab";
import Icon from "@mdi/react";
import IconButton from "@material-ui/core/IconButton";
import { mdiMicrosoftExcel, mdiCallMade, mdiCallReceived } from "@mdi/js";
import { useGlobalState } from "../../store";
import {
  fDate,
  fBytes,
  openEtherscanTransaction,
  getBackground,
} from "../../util";
import { ETHERSCAN_URL } from "../../config";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    download: {
      width: "3rem",
      height: "3rem",
      position: "fixed",
      bottom: "1rem",
      right: "1rem",
    },
    progress: {
      width: "5rem",
      bottom: "1rem",
      left: "0.4rem",
      position: "absolute",
      borderRadius: "5px",
      height: "1.4rem",
      opacity: 0.6,
    },
    waiting: {
      opacity: 0.5,
      background: "rgba(0,0,0,0.05)",
    },
    button: {
      padding: "2px",
    },
  })
);

export default function TransactionHistory({
  selectedClient,
  transactionHistory,
  hideDownload = false,
}) {
  const classes = useStyles();
  const [userLedger] = useGlobalState("userLedger");
  const [ethereumAccount] = useGlobalState("ethereumAccount");

  if (!transactionHistory || transactionHistory.length <= 0)
    return (
      <Typography
        variant="body2"
        style={{ marginTop: "2rem", opacity: 0.8 }}
      >{`No transfer history found`}</Typography>
    );

  const csvData = transactionHistory.map(
    ({ _timestamp, from, to, _name, _size, transactionHash }) => {
      return {
        date: fDate(_timestamp),
        sender: from,
        receiver: to,
        filename: _name,
        size: `${_size}B (${fBytes(_size)})`,
        "Ethereum Link": `${ETHERSCAN_URL}/tx/${transactionHash}`,
      };
    }
  );

  return (
    <div className={classes.root}>
      <Table
        className={classes.table}
        aria-label="simple table"
        style={{ borderTop: hideDownload ? "1px solid #e0e0e0" : "none" }}
      >
        <TableBody>
          {transactionHistory.map((tx) => {
            const isWaiting = tx.transactionHash === "waiting";
            return (
              <TableRow
                className={`${isWaiting ? classes.waiting : ""}`}
                key={tx.transactionHash}
              >
                <TableCell
                  style={{ paddingLeft: "4rem", opacity: isWaiting ? 0 : 1 }}
                >
                  <IconButton
                    aria-label="delete"
                    className={classes.button}
                    onClick={() => openEtherscanTransaction(tx.transactionHash)}
                  >
                    {tx._from === selectedClient ? (
                      <Icon path={mdiCallReceived} size={0.7} color="green" />
                    ) : (
                      <Icon path={mdiCallMade} size={0.7} color="red" />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell style={{ position: "relative" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ padding: "1.5px 0" }}
                  >
                    {isWaiting ? "waiting ..." : fDate(tx._timestamp)}
                    {isWaiting && (
                      <LinearProgress
                        className={classes.progress}
                        color={"primary"}
                      />
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="secondary">{tx._name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{`${fBytes(
                    tx._size
                  )}`}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!hideDownload && (
        <CSVLink
          data={csvData}
          filename={`Transfer-log for ${
            userLedger[selectedClient].name
          } at ${fDate()}.csv`}
        >
          <Fab
            className={classes.download}
            color={"primary"}
            aria-label="download"
            style={{ background: getBackground(ethereumAccount) }}
          >
            <Icon path={mdiMicrosoftExcel} size={1} />
          </Fab>
        </CSVLink>
      )}
    </div>
  );
}
