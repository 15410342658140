import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { socialSharing } from "../../config";

import {
  FacebookShareCount,
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

/**
 * Social sharing buttons
 */

const styles = {
  root: {
    position: "fixed",
    bottom: "0.4rem",
    right: "4rem",
    zIndex: "1",
  },
  network: {
    verticalAlign: "super",
    display: "inline-block",
    marginRight: "15px",
    textAlign: "center",
  },
  count: {
    marginTop: "3px",
    fontSize: "12px",
  },
  share: {
    cursor: "pointer",
    outline: "none",
  },
  custom: {
    width: "32px",
    height: "32px",
  },
  fbLike: {
    margin: "0.55rem 0.15rem 0 0.35rem",
    minWidth: "50px",
    display: "inline-block",
  },
};

class SocialSharing extends React.Component {
  render() {
    const { classes } = this.props;
    const { shareUrl, title, hashtag, description } = socialSharing;

    return (
      <div className={classes.root}>
        <div className={classes.network}>
          <FacebookShareCount url={shareUrl} className={classes.count}>
            {(count) => count}
          </FacebookShareCount>
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            hashtag={hashtag}
            className={classes.share}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>

        <div className={classes.network}>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            hashtags={[hashtag]}
            className={classes.share}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>

        <div className={classes.network}>
          <WhatsappShareButton url={shareUrl} className={classes.share}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>

        <div className={classes.network}>
          <LinkedinShareButton
            url={shareUrl}
            title={title}
            windowWidth={750}
            windowHeight={600}
            className={classes.share}
            description={description}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div className={classes.network}>
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body={description}
            className={classes.share}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      </div>
    );
  }
}

SocialSharing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialSharing);
