import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { SERVER_URL } from "../../config";

const styles = (theme) => ({
  root: {
    padding: "1rem 0 3rem 0",
    margin: "8rem 4rem 0",
    border: "1px solid rgba(0,0,0,0.1)",
    width: "calc(100% - 8rem)",
  },
  button: {
    verticalAlign: "top",
    marginLeft: "0.5rem",
    width: "7rem",
  },
  progress: {
    position: "absolute",
    width: "6.4rem",
    height: "2.25rem",
    top: "0",
    right: "3rem",
    borderRadius: "4px",
    opacity: "0.4",
  },
});

function Decrypter(props) {
  const { classes } = props;
  const [encrypted, setEncrypted] = useState();
  const [decrypted, setDecrypted] = useState();
  const [decrypting, setDecrypting] = useState(false);

  return (
    <Paper className={classes.root} elevation={0}>
      <TextField
        id="encrypted"
        label={"Encrypted text"}
        value={encrypted}
        style={{ width: "40%" }}
        onChange={({ target }) => setEncrypted(target.value)}
      />
      <div
        style={{
          display: "inline-block",
          marginTop: "0.5rem",
          position: "relative",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!encrypted}
          style={{ margin: "0 3rem" }}
          onClick={async () => {
            try {
              setDecrypting(true);
              const response = await fetch(`${SERVER_URL}/decrypt`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ payload: encrypted }),
              });
              setDecrypted((await response.json()).decrypted);
              setDecrypting(false);
            } catch (error) {
              setDecrypted(`Error: ${error}`);
              setDecrypting(false);
            }
          }}
        >
          Decrypt
        </Button>
        {decrypting && (
          <LinearProgress className={classes.progress} color={"primary"} />
        )}
      </div>
      <TextField
        id="encrypted"
        value={decrypted}
        style={{ width: "40%" }}
        disabled={true}
      />
    </Paper>
  );
}

export default withStyles(styles)(Decrypter);
