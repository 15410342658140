import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import UserTable from "./UserTable";
import AddUser from "./AddUser";
import Decrypter from "./Decrypter";
import { useGlobalState } from "../../store";
import { SERVER_URL } from "../../config";

const useStyles = makeStyles((theme) => createStyles({}));

export default function AdminPage() {
  const classes = useStyles();
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [, setLoading] = useGlobalState("loading");
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (ethereumAccount) setLoading(false);
  }, [ethereumAccount, setLoading]);
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/viewplan`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setPlans(data.plans);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };
    fetchPlans();
  }, []);
  return (
    <div>
      <Decrypter />
      <AddUser plans={plans} />
      <UserTable className={classes.root} plans={plans} />
    </div>
  );
}