import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useGlobalState } from "../../store";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: "transparent",
      zIndex: 1000,
    },
  })
);

export default function Freeze() {
  const [freeze] = useGlobalState("freeze");
  const classes = useStyles();
  return freeze ? (
    <div
      className={classes.root}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    ></div>
  ) : (
    <div />
  );
}
