import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import { useGlobalState } from "../../store";
import { getBackground } from "../../util";
import Preview from "./Preview";
import { TnC_URL } from "../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={3000} ref={ref} {...props} />;
});

export default function Disclaimer() {
  const [lastPageRead, setLastPageRead] = useState(false);
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [disclaimerAccepted, setDisclaimerAccepted] =
    useGlobalState("disclaimerAccepted");

  return (
    <Dialog
      fullScreen
      open={!disclaimerAccepted}
      TransitionComponent={Transition}
    >
      <AppBar
        position="static"
        style={{ background: getBackground(ethereumAccount) }}
      >
        <Toolbar style={{ position: "relative" }}>
          <img
            src={`logo.png`}
            alt="x"
            style={{ width: "2rem", height: "2rem", marginRight: "1rem" }}
          />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`MediSend Terms of use`}
          </Typography>
          <Button
            disabled={!lastPageRead}
            onClick={() => setDisclaimerAccepted(true)}
            style={{ position: "absolute", right: "2rem" }}
          >
            <span style={{ color: "white", opacity: lastPageRead ? 1 : 0.5 }}>
              ACCEPT
            </span>
          </Button>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          margin: "2rem 0 0 0",
        }}
      >
        <Preview
          file={`Disclaimer.pdf`}
          onLastPage={() => setLastPageRead(true)}
          seamless={true}
          onClick={() => window.open(TnC_URL, "_blank")}
        />
      </div>
    </Dialog>
  );
}
