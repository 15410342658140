import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Paper from "@material-ui/core/Paper";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "inline-block",
    },
  })
);

export default function Preview({
  file,
  onLastPage,
  seamless,
  onClick,
  type = "pdf",
}) {
  console.log("🚀 ~ file: Preview.js:20 ~ Preview ~ file:", file);
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const theme = useTheme();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    if (numPages && onLastPage) onLastPage();
  }

  return (
    <Paper
      elevation={seamless ? 0 : 3}
      className={classes.root}
      onClick={() => onClick && onClick()}
    >
      {/* mobile stepper */}
      {numPages > 1 && (
        <MobileStepper
          style={{ maxWidth: "50vw", overflowX: "scroll" }}
          variant="dots"
          steps={numPages}
          position="static"
          activeStep={pageNumber - 1}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          nextButton={
            <Button
              size="small"
              onClick={() => {
                setPageNumber((pageNumber) => pageNumber + 1);
                if (pageNumber + 1 === numPages && onLastPage) onLastPage();
              }}
              disabled={pageNumber === numPages}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => setPageNumber((pageNumber) => pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      )}
      {/* pdf preview */}
      {(type === "application/pdf" ||
        (typeof file === "string" && file.includes(".pdf"))) && (
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        )}
      {type === "image/jpeg" && (
        <img
          src={typeof file === "string" ? file : URL.createObjectURL(file)}
          alt=""
        />
      )}
      {type === "text/xml" && <img src={"xml-icon.png"} alt="" />}
    </Paper>
  );
}