import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@mdi/react";
import {
  mdiEthereum,
  mdiFaceAgent,
  mdiChartBar,
  mdiVolumeHigh,
  mdiVolumeOff,
} from "@mdi/js";
import { useGlobalState } from "../../store";
import SearchBar from "./SearchBar";
import {
  getBackground,
  openEtherscanAccount,
  openEtherscanTransaction,
  sendEmail,
} from "../../util";
import { VERSION } from "../../config";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      top: 0,
      position: "fixed",
      width: "100%",
      zIndex: 1,
    },
    logo: {
      width: "2rem",
      height: "2rem",
      borderRadius: "50%",
      boxShadow: "0px 0px 1px 1px #ffffff",
      transition: "box-shadow 0.5s",
    },
    highlight: {
      boxShadow: "0px 0px 8px 8px #ffffff",
    },
    title: {
      flexGrow: 1,
      marginLeft: "4rem",
    },
    version: {
      position: "absolute",
      top: "0",
      right: "-3.25rem",
      fontWeight: "bold",
      fontSize: "0.5rem",
      letterSpacing: "1px",
      color: "white",
    },
    searchBar: {
      position: "absolute",
      left: "10rem",
      top: "1rem",
      borderRadius: "4px",
      boxShadow: "0px 0px 2px 2px rgba(255,255,255,0.3)",
      "&:hover": {
        boxShadow: "0px 0px 2px 2px rgba(255,255,255,1)",
      },
      zIndex: 2,
    },
  })
);

export default function ButtonAppBar() {
  const classes = useStyles();
  const [heading] = useGlobalState("heading");
  const [selectedClient, setSelectedClient] = useGlobalState("selectedClient");
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [ethereumTxnBeingMined] = useGlobalState("ethereumTxnBeingMined");
  const [nwBlip] = useGlobalState("nwBlip");
  const [loading] = useGlobalState("loading");
  const [userLedger] = useGlobalState("userLedger");
  const [mute, setMute] = useGlobalState("mute");

  //const { icon } = userLedger[ethereumAccount] ? userLedger[ethereumAccount] : { icon: "logo.png" };
  const icon = "logo.png";

  return (
    <div className={classes.root}>
        {!loading && (
      <AppBar
        position="static"
        style={{ background: getBackground(ethereumAccount) }}
      >
      
          <div className={classes.searchBar}>
            <SearchBar />
          </div>
       
        <Toolbar>
          <div style={{ position: "relative" }}>
            <img
              alt="logo"
              src={icon || "logo.png"}
              className={`${classes.logo} ${nwBlip ? classes.highlight : ""}`}
            />
            <Typography variant="caption" className={classes.version}>
              {VERSION}
            </Typography>
          </div>
          {!selectedClient && (
            <Typography variant="h6" className={classes.title}>
              {heading}
            </Typography>
          )}
          {selectedClient && (
            <Typography variant="h6" className={classes.title}>
              {heading}
              <div style={{ display: "inline-block", margin: "0 2rem" }}>
                {" ⮕ "}
              </div>
              {userLedger[selectedClient].name}
            </Typography>
          )}
          <IconButton
            color="inherit"
            onClick={() => setSelectedClient(null)}
            disabled={!selectedClient}
          >
            <Icon path={mdiChartBar} size={1} />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => setMute(!mute)}
            className={classes.mute}
          >
            <Icon path={mute ? mdiVolumeOff : mdiVolumeHigh} size={1} />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() =>
              ethereumTxnBeingMined
                ? openEtherscanTransaction(ethereumTxnBeingMined)
                : openEtherscanAccount(ethereumAccount)
            }
          >
            <Icon
              path={mdiEthereum}
              size={1}
              className={ethereumTxnBeingMined ? "rotate" : ""}
            />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => sendEmail("support@medisend.com.au", "Medisend support message from", "Please tell us about your complaint or compliment.")}
            className={classes.support}
          >
            <Icon path={mdiFaceAgent} size={1} />
          </IconButton>
        </Toolbar>
      </AppBar>
      )}
    </div>
  );
}