import { createStyles, makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import UploadIcon from "@material-ui/icons/PostAdd";
import ChatIcon from "@material-ui/icons/Chat";
import Split from "react-split-it";
import ClientList from "./ClientList";
import FileUploader from "./FileUploader";
import FileDownloader from "./FileDownloader";
import TransactionHistory from "./TransactionHistory";
import Homepage from "./Analytics";
import { useGlobalState } from "../../store";
import { getBackground } from "../../util";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: "4rem",
      left: 0,
      width: "100%",
      height: "calc(100vh - 4rem)",
      overflowY: "scroll",
      zIndex: 1,
    },
    chat: {
      width: "3rem",
      height: "3rem",
      position: "fixed",
      bottom: "9rem",
      right: "1rem",
    },
    upload: {
      width: "3rem",
      height: "3rem",
      position: "fixed",
      bottom: "5rem",
      right: "1rem",
    },
    close: {
      transform: "scale(0.5)",
      width: "3rem",
      height: "3rem",
      position: "absolute",
      top: "1rem",
      right: "1rem",
    },
  })
);

export default function ClientPage() {
  const classes = useStyles();
  const [showUploader, setShowUploader] = useGlobalState("showUploader");
  const [showChat, setShowChat] = useGlobalState("showChat");
  const [selectedClient] = useGlobalState("selectedClient");
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [activeClientList] = useGlobalState("activeClientList");
  const [systemState] = useGlobalState("systemState");
  const [transferLedger] = useGlobalState("transferLedger");
  const [fileDownloadObj] = useGlobalState("fileDownloadObj");

  const bg = getBackground(ethereumAccount);

  return (
    <div className={classes.root}>
      {!fileDownloadObj && (
        <Split sizes={[0.2, 0.8]}>
          <div className="content r">
            <ClientList />
          </div>
          <div className="content o">
            {selectedClient && selectedClient === "Non-Medisend" && (
              <p>During the beta program files sent to Non-Medisend users will not be visible in transfer history</p>
            )}
            <Divider style={{ background: "#393d40" }}/>
            {selectedClient && <FileUploader />}
            {selectedClient && (
              <TransactionHistory
                selectedClient={selectedClient}
                transactionHistory={transferLedger[selectedClient]}
              />
            )}
            {!selectedClient && <Homepage />}
          </div>
        </Split>
      )}
      {fileDownloadObj && <FileDownloader />}
      {/* show/hide chat */}
      {activeClientList.includes(selectedClient) && selectedClient && selectedClient !== "Non-Medisend" && ( // Disable chat option for non-medisend (replace with file)
        <Fab
          className={classes.chat}
          color={"primary"}
          style={{
            background: showChat ? "#cccccc" : bg,
            bottom: fileDownloadObj ? "1rem" : "9rem",
          }}
          aria-label="chat"
          onClick={() => setShowChat(true)}
          disabled={showChat}
        >
          <ChatIcon />
        </Fab>
      )}
      {/* show uplaod */}
      {systemState === "idle" &&
        activeClientList.includes(selectedClient) &&
        selectedClient && (
          <Fab
            className={classes.upload}
            color={"primary"}
            style={{
              background: showUploader ? "#cccccc" : bg,
            }}
            aria-label="upload"
            onClick={() => setShowUploader(true)}
            disabled={showUploader}
          >
            <UploadIcon />
          </Fab>
        )}
      {/* show/hide csv */}
      {systemState === "waitingForDownload" && (
        <Fab
          className={`${classes.upload} rotate`}
          color={"primary"}
          style={{ background: bg }}
          aria-label="upload"
        >
          <DownloadIcon />
        </Fab>
      )}
    </div>
  );
}
