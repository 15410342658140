
const TnC_URL =
  "https://www.medisend.com.au/User+Account+Terms+and+Conditions.html";
const ETHERSCAN_URL = "https://polygonscan.com/";
const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? "https://medisend.herokuapp.com"
    : // : "https://medisend.herokuapp.com";
    "http://192.168.29.234:4000";
const PEER_TIMEOUT_SEC = 60;
const TXN_HISTORY_HOURS = 24;
const TUTORIAL_URL =
  "https://www.youtube.com/embed/videoseries?list=PL3FOIyArJIEPZvpNtJ7dJQX-Q_wERngmt";
const socialSharing = {
  shareUrl: `https://www.medisend.com.au`,
  title: "Medisend -  Secure medical records transfer",
  hashtag: "#medisend",
  description: `World's first peer-to-peer file transfer app, where the integrity and history of transactions is guaranteed on Ethereum, world's largest public blockchain. Apply to participate in a limited Beta now.`,
};

const VERSION = "v1.5";

module.exports = {
  TnC_URL,
  ETHERSCAN_URL,
  TUTORIAL_URL,
  SERVER_URL,
  PEER_TIMEOUT_SEC,
  VERSION,
  TXN_HISTORY_HOURS,
  socialSharing,
};