import { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Rnd } from "react-rnd";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import { useGlobalState } from "../../store";
import { remToPx, flashOff, hDate } from "../../util";
import { sendChatMessage } from "../../socket";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "fixed !important",
      background: "white",
      borderRadius: "6px",
      boxShadow: "0px 0px 6px 6px rgb(160,160,160)",
      zIndex: 1111111,
      display: "flex",
      flexWrap: "wrap",
    },
    close: {
      position: "absolute",
      top: "-3px",
      right: "-3px",
      transform: "scale(0.5)",
      margin: "-1.5rem -1.5rem 0 0",
      zIndex: 111,
    },
    top: {
      width: "100%",
      height: "calc(100% - 6rem)",
      maxHeight: "calc(100% - 6rem)",
      overflow: "scroll",
      padding: "0 1rem",
      marginTop: "1rem",
    },
    row: {
      width: "calc(100% - 2rem)",
      marginBottom: "1rem",
    },
    bottom: {
      width: "100%",
      height: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    text: {
      width: "calc(100% - 10rem)",
    },
    icon: {
      width: "2rem",
      height: "2rem",
    },
    timestamp: {
      position: "absolute",
      top: "0.7rem",
      left: "1rem",
      fontSize: "0.5rem",
      letterSpacing: "1px",
    },
    rightMsg: {
      left: "auto",
      right: "1rem",
    },
  })
);

export default function Chat() {
  const classes = useStyles();
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [showChat, setShowChat] = useGlobalState("showChat");
  const [selectedClient] = useGlobalState("selectedClient");
  const [chatHistory] = useGlobalState("chatHistory");
  const [userLedger] = useGlobalState("userLedger");

  const [message, setMessage] = useState();
  const [size, setSize] = useState({ width: remToPx(40), height: remToPx(20) });
  const [position, setPosition] = useState({
    x: remToPx(1.1),
    y: window.screen.availHeight - remToPx(26),
  });

  useEffect(() => {
    document.getElementById("__last") &&
      setTimeout(
        () =>
          document
            .getElementById("__last")
            .scrollIntoView({ behavior: "smooth" }),
        100
      );
  }, [chatHistory]);

  const doSend = () => {
    sendChatMessage(message);
    setMessage("");
  };

  const _chatHistory = chatHistory[selectedClient] || [];
  const { icon } = userLedger[selectedClient];

  if (!showChat) return <div />;
  return (
    <Rnd
      className={classes.root}
      minWidth={640}
      minHeight={320}
      position={position}
      onDragStop={(e, p) => setPosition(p)}
      size={size}
      onResizeStop={(e, d, { style }, f, position) => {
        setSize(style);
        setPosition(position);
      }}
      onClick={() => flashOff()}
    >
      <Fab
        color="secondary"
        className={classes.close}
        onClick={() => setShowChat(false)}
      >
        <CloseIcon />
      </Fab>
      <div className={classes.top + " keep-scrolling"}>
        {_chatHistory.map(({ fromAccountId, message, timestamp }, i) => {
          const isMyMsg = fromAccountId === ethereumAccount;
          return (
            <div
              id={i === _chatHistory.length - 1 ? "__last" : ""}
              key={i}
              className={classes.row}
              style={{
                textAlign: isMyMsg ? "left" : "right",
              }}
            >
              <div className={`message ${isMyMsg ? "" : "right"}`}>
                <div
                  className={
                    classes.timestamp + " " + (isMyMsg ? "" : classes.rightMsg)
                  }
                >
                  {hDate(timestamp)}
                </div>
                {message}
              </div>
            </div>
          );
        })}
        <div className={"anchor"}></div>
      </div>
      <div className={classes.bottom}>
        {icon && <img src={icon} alt="d" className={classes.icon} />}
        <TextField
          id="message"
          variant="outlined"
          className={classes.text}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={({ key }) => key === "Enter" && doSend()}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ verticalAlign: "bottom" }}
          disabled={!message || !message.trim()}
          onClick={doSend}
        >
          Send
        </Button>
      </div>
    </Rnd>
  );
}
